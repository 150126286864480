<template>
    <div>
        <v-dialog v-model="dialog_delete_appointment" max-width="600px">
            <v-card>
                <v-card-title class="text-h5">Soll dieser Termin wirklich gelöscht werden?</v-card-title>
                <v-card-text class="text-body-1">
                    Die Dokumentation dieses Termins sowie zugehörige Dokumente werden ebenso gelöscht.
                </v-card-text>
                <v-card-actions class="px-6 pb-5">
                    <v-spacer></v-spacer>
                    <v-btn :color="$store.state.theme.primary" text @click="dialog_delete_appointment = false" :disabled="deleting_appointment">Nein</v-btn>
                    <v-btn class="ml-2" :color="$store.state.theme.red" outlined @click="deleteAppointmentConfirm" :loading="deleting_appointment">Ja</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_create_appointment" persistent eager :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card>
                <v-toolbar elevation="0">
                    <v-btn icon @click="closeCreateAppointment" :disabled="inserting_entry">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        <span>{{ dialogTitle }}</span> 
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-0 pt-0">
                    <v-row class="my-0 py-0" v-if="$vuetify.breakpoint.smAndUp">
                        <v-col class="d-flex align-center justify-center my-0 py-0">
                            <v-btn fab text small :color="$store.state.theme.primary" @click="$refs.calendar.prev()">
                                <v-icon> mdi-chevron-left </v-icon>
                            </v-btn>
                            <v-toolbar-title class="text-body-1 mx-4" v-if="$refs.calendar">
                                {{ $refs.calendar.title() }}
                            </v-toolbar-title>
                            <v-btn fab text small :color="$store.state.theme.primary" @click="$refs.calendar.next()">
                                <v-icon> mdi-chevron-right </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="my-0 py-0">
                        <v-col 
                            cols="12" 
                            :lg="showDetailsColumn ? 8 : 12"
                            :md="showDetailsColumn ? 7 : 12"
                            order="2" 
                            order-md="1" 
                        >
                            <div v-if="$vuetify.breakpoint.xsOnly" cols="12" class="d-flex align-center justify-center my-0 pt-0 pb-5">
                                <v-btn fab text small :color="$store.state.theme.primary" @click="$refs.calendar.prev()">
                                    <v-icon small> mdi-chevron-left </v-icon>
                                </v-btn>
                                <v-toolbar-title class="text-body-1 mx-4" v-if="$refs.calendar">
                                    {{ $refs.calendar.title() }}
                                </v-toolbar-title>
                                <v-btn fab text small :color="$store.state.theme.primary" @click="$refs.calendar.next()">
                                    <v-icon small> mdi-chevron-right </v-icon>
                                </v-btn>
                            </div>
                            <v-row class="d-flex justify-center align-center my-1" v-if="!(showDetailsColumn || $vuetify.breakpoint.xsOnly)">
                                <span class="text-caption-1">
                                    <v-icon left small> mdi-cursor-default-click-outline </v-icon>
                                    Klicke auf einen Zeitpunkt im Kalender um einen Termin zu erstellen
                                </span>
                            </v-row>
                            <Calendar
                                ref="calendar"
                                :height="computedHeight"
                                :session="session"
                                @selectedDateAndTime="updateDateAndTime"
                                :editedItem="editedItem"
                                @showError="(arg) => $emit('showError', arg)"
                                @showInfo="(arg) => $emit('showInfo', arg)" />
                        </v-col>
                        <v-col
                            cols="12" 
                            md="5" 
                            lg="4"
                            v-show="showDetailsColumn || $vuetify.breakpoint.xsOnly" 
                            appear
                            order="1" 
                            order-md="2" 
                        >
                            <v-slide-y-transition> 
                                <v-card outlined v-show="showDetailsColumn || $vuetify.breakpoint.xsOnly" 
                                        :style="{
                                        borderRadius: '4px',
                                        border: service && service.farbe ? '2px dashed ' + service.farbe : '',
                                    }">
                                    <v-card-text>
                                        <v-form v-model="valid">
                                            <v-row class="my-0 py-0">
                                                <v-col cols="12" class="d-flex align-center justify-start my-0 pt-0 pb-5">
                                                    <v-icon left>mdi-account</v-icon>
                                                    <span class="text-body-1">{{ client.nachname + " " + client.vorname }}</span>
                                                </v-col>
                                                <v-col cols="12" class="py-0 my-0">
                                                    <v-autocomplete v-model="service" :loading="!loaded_services" loader-height="5" :disabled="!loaded_services" 
                                                        :items="services" item-text="bezeichnung" item-value="id" return-object 
                                                        label="Dienstleistung" outlined dense @change="changedService" clearable clear-icon="mdi-close-circle" :rules="serviceRule">
                                                        <template v-slot:item="{ item }">
                                                            <div class="d-flex align-center py-3 py-sm-0">
                                                                <span>{{ item.bezeichnung }}</span>
                                                                <v-chip v-if="item.dauer !== null || item.preis !== null" class="ml-2" small>
                                                                    <span v-if="item.dauer !== null && item.preis !== null">{{ item.preis }} € / {{ item.dauer }} Min.</span>
                                                                    <span v-else-if="item.dauer !== null">{{ item.dauer }} Min.</span>
                                                                    <span v-else-if="item.preis !== null">{{ item.preis }} €</span>
                                                                </v-chip>
                                                            </div>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="6" class="py-0 my-0">
                                                    <v-text-field dense outlined type="number" v-model="editedItem.preis" label="Preis" />
                                                </v-col>
                                                <v-col cols="6" class="py-0 my-0">
                                                    <v-text-field dense outlined type="number" v-model="editedItem.dauer" label="Dauer (Minuten)" />
                                                </v-col>
                                                <v-col cols="6" class="py-0 my-0">
                                                    <v-menu :close-on-content-click="true" offset-y max-width="290px" min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                v-model="editedItem.datumFormatted"
                                                                label="Datum"
                                                                hint=""
                                                                prepend-inner-icon="mdi-calendar"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @input="updateDate($event)"
                                                                :rules="dateRule"
                                                                required />
                                                        </template>
                                                        <v-date-picker dense outlined first-day-of-week="1" v-model="editedItem.datum" no-title @input="updateAndClose($event)" />
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="6" class="d-flex py-0 my-0">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        v-model="editedItem.uhrzeit"
                                                        label="Uhrzeit"
                                                        value="12:00:00"
                                                        :type="editedItem.uhrzeit ? 'time' : 'time'"
                                                        :rules="timeRule"
                                                        required />
                                                </v-col>
                                                <v-col cols="12" class="d-flex my-0 py-0 align-center justify-start" v-if="client.termin_erinnerung">
                                                    <v-tooltip bottom v-if="editedItem.erinnerung_gesendet !== null">
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on" class="mr-5" :color="$store.state.theme.orange">mdi-alert</v-icon>
                                                        </template>
                                                        <span>Eine Termin-Erinnerung wurde bereits versendet. Falls du das Datum oder die Uhrzeit änderst, wird keine neue Erinnerung versendet.</span>
                                                    </v-tooltip>
                                                    <v-switch :append-icon="editedItem.termin_erinnerung === true ? 'mdi-bell-ring' : 'mdi-bell-off'" class="my-0 ml-1 mr-5" v-model="editedItem.termin_erinnerung" inset
                                                        :color="$store.state.theme.green" :disabled="editedItem.erinnerung_gesendet !== null" hide-details />
                                                    <span class="text-body-1">
                                                        {{ editedItem.termin_erinnerung ? '' : 'Keine ' }}Termin-Erinnerung per SMS
                                                    </span>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-btn block @click="save" :color="$store.state.theme.green" :dark="valid" :disabled="!valid" :loading="inserting_entry">
                                                        Termin Erstellen
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-slide-y-transition>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import dayjs from "dayjs";
import connector from "../helpers/supabase-connector.js";
import Calendar from "./Calendar.vue";

export default {
    props: ["client", "defaultItem", "session", "clientAppointments"],
    emits: ["refreshAppointments", "showError", "showInfo"],
    components: {
        Calendar,
    },

    data() {
        return {
            dialog_create_appointment: false,
            dialog_delete_appointment: false,
            appointment_id_to_delete: null,
            deleting_appointment: false,

            inserting_entry: false,

            service: null,
            services: [],
            loaded_services: false,

            valid: false,
            dateRule: [
                v => !!v || 'Datum wird benötigt',
                v => /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(\d{4})$/.test(v) || 'Datum muss das Format TT.MM.JJJJ haben.',
            ],
            timeRule: [
                (v) => !!v || "Uhrzeit ist erforderlich"
            ],
            serviceRule: [
                v => !!v || 'Bitte wähle eine Dienstleistung aus.',
            ],
            editedItem: Object.assign({}, this.defaultItem),
        };
    },

    computed: {

        showDetailsColumn() {
            if (this.editedItem.datum && this.editedItem.uhrzeit) {
                return true;
            } else {
                return false;
            }
        },

        dialogTitle() {
            return this.editedItem.id ? "Termin bearbeiten" : "Neuer Termin";
        },

        googleCalenderAuthState() {
            let linked_google_account = this.linked_google_account;
            let linked_google_calendar = this.$store.state.client.google_calendar;
            let provider_refresh_token = localStorage.provider_refresh_token;

            if (provider_refresh_token && linked_google_calendar) {
                return "synced";
            } else if (provider_refresh_token && !linked_google_calendar) {
                return "authorized";
            } else if (linked_google_account) {
                return "linked";
            } else {
                return "unlinked";
            }
        },

        computedHeight() {
            if (this.$vuetify.breakpoint.xs) {
                return "55vh";
            } else if (this.$vuetify.breakpoint.sm) {
                return "55vh";
            } else if (this.$vuetify.breakpoint.md) {
                return "70vh";
            } else if (this.$vuetify.breakpoint.lg) {
                return "70vh";
            } else if (this.$vuetify.breakpoint.xl) {
                return "70vh";
            }
        },
    },

    methods: {
        changedService(event) {
            if(event) {
                let service = this.services.find((service) => service.id === event.id);
                this.editedItem.preis = service.preis;
                this.editedItem.dauer = service.dauer;
                this.editedItem.bezeichnung = service.bezeichnung;
                this.editedItem.ust_befreiung = service.ust_befreiung;
                this.editedItem.fk_dienstleistung = service.id;
                this.editedItem.farbe = service.farbe;
            }
        },

        async deleteAppointmentConfirm() {
            this.deleting_appointment = true;

            let deleted_files = true;
            // delete files from the corresponding bucket (if any)
            if (this.session.user.id && this.client.id) {
                let client_id = this.client.id;
                let path = this.session.user.id + "/" + client_id + "/" + this.appointment_id_to_delete + "/";
                deleted_files = await connector.deleteAllFilesInBucket(this, "documentation", path);
                if (!deleted_files) {
                    // error has already been shown
                    // stop deleting
                    this.deleting_appointment = false;
                    return;
                }
            }

            let deleted = await connector.delete(this, "termine", "id", this.appointment_id_to_delete);
            if (!deleted) {
                // error has already been displayed
                this.deleting_appointment = false;
                return;
            }

            // check if google calendar is connected and if yes, also delete the appointment from the google calendar
            if (this.$store.state.client.google_calendar) {
                await this.$refs.calendar.deleteGoogleEvent(this.appointment_id_to_delete);
            }

            this.dialog_delete_appointment = false;
            this.deleting_appointment = false;
            this.appointment_id_to_delete = null;
            this.$emit("refreshAppointments", false);

            if (deleted && deleted_files) {
                this.$emit('showInfo', {
                    message: 'Der Termin wurde erfolgreich gelöscht.',
                    timeout: 5000
                });
            }
        },

        deleteAppointment(item) {
            this.appointment_id_to_delete = item.id;
            this.dialog_delete_appointment = true;
        },

        newAppointment() {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedItem.vorname = this.client.vorname;
            this.editedItem.nachname = this.client.nachname;

            // set default termin_erinnerung to true if the klient has termin_erinnerung set to true
            // and if we are creating a new appointment, otherwise keep the value
            if (this.client.termin_erinnerung && this.editedItem.termin_erinnerung === null) {
                this.editedItem.termin_erinnerung = true;
            }
            this.loaded_services = false;
            this.initialize();
            this.$nextTick(() => {
                this.$refs.calendar.initialize();
            });

            this.dialog_create_appointment = true;
        },

        editAppointment(item) {
            this.editedItem = Object.assign({}, item);
            this.editedItem.vorname = this.client.vorname;
            this.editedItem.nachname = this.client.nachname;
            this.loaded_services = false;
            this.updateDateAndTime(dayjs(this.editedItem.datum));

            this.initialize();
            this.$nextTick(() => {
                this.$refs.calendar.initialize();
            });

            this.dialog_create_appointment = true;
        },

        async initialize() {
            this.services = await connector.getDataOnly(this, "vwdienstleistungen", "id", true);
            this.loaded_services = true;

            // for some reason the dialog watch is not triggered on the first load / opening, therefore set appointments here, as this is called on first dialog open
            // check if editing item, if yes and fk_dienstleistung is set, set service
            // set the first service as default
            // check if editing item, if yes and fk_dienstleistung is set, set service
            if (this.editedItem.fk_dienstleistung) {
                this.service = this.services.find((service) => service.id === this.editedItem.fk_dienstleistung);
            } else {

                if (this.services.length > 0) {
                    // check if the client already had some appointments, if yes, set the serive to the service of his last appointment
                    if (this.clientAppointments.length > 0) {
                        let last_appointment = this.clientAppointments.sort((a, b) => dayjs(b.datum) - dayjs(a.datum))[0]
                        this.service = this.services.find((service) => service.id === last_appointment.fk_dienstleistung);
                        if (!this.service) {
                            // if for some reason, the services is not found, set to the first one.
                            // this could probably happen in old cases, where there were no services.
                            this.service = this.services[0];
                        }

                        this.$set(this.editedItem, 'preis', this.service.preis);
                        this.$set(this.editedItem, 'dauer', this.service.dauer);
                        this.$set(this.editedItem, 'bezeichnung', this.service.bezeichnung);
                        this.$set(this.editedItem, 'ust_befreiung', this.service.ust_befreiung);
                        this.$set(this.editedItem, 'fk_dienstleistung', this.service.id);
                        this.$set(this.editedItem, 'farbe', this.service.farbe);

                    } else {
                        // the client has no appointments yet, therefore set the first service as default
                        this.service = this.services[0];

                        this.$set(this.editedItem, 'preis', this.service.preis);
                        this.$set(this.editedItem, 'dauer', this.service.dauer);
                        this.$set(this.editedItem, 'bezeichnung', this.service.bezeichnung);
                        this.$set(this.editedItem, 'ust_befreiung', this.service.ust_befreiung);
                        this.$set(this.editedItem, 'fk_dienstleistung', this.service.id);
                        this.$set(this.editedItem, 'farbe', this.service.farbe);
                    }
                }
            }
        },

        updateDateAndTime(date) {
            this.editedItem.datum = date.format("YYYY-MM-DD");
            this.editedItem.datumFormatted = date.format("DD.MM.YYYY");
            this.editedItem.uhrzeit = date.format("HH:mm");
        },

        updateAndClose(value) {
            // check if date is properly formatted using dayjs (it comes from the calender click, therefore this format)
            if (!dayjs(value, "YYYY-MM-DD").isValid()) {
                return;
            }

            let date = dayjs(value, "YYYY-MM-DD").format("YYYY-MM-DD");
            this.editedItem.datum = date;
            this.editedItem.datumFormatted = dayjs(value, "YYYY-MM-DD").format("DD.MM.YYYY");
        },

        updateDate(value) {
            // check if date is properly formatted using dayjs
            if (!dayjs(value, "DD.MM.YYYY").isValid()) {
                return;
            }

            let date = dayjs(value, "DD.MM.YYYY").format("YYYY-MM-DD");
            this.editedItem.datum = date;
            this.editedItem.datumFormatted = dayjs(value, "DD.MM.YYYY").format("DD.MM.YYYY");
        },

        closeCreateAppointment() {
            this.dialog_create_appointment = false;
            this.service = null;
            this.editedItem = Object.assign({}, this.defaultItem);
        },

        async save() {
            this.inserting_entry = true;

            // check if service is set
            if (!this.service) {
                this.$emit("showError", {
                    message: "Bitte wähle eine Dienstleistung aus.",
                });
                this.inserting_entry = false;
                return;
            }

            // check if preis is set
            if (this.editedItem.preis === null || this.editedItem.preis === "" || this.editedItem.preis === undefined) {
                this.$emit("showError", { 
                    message: "Bitte gib einen Preis ein. Der Preis kann auch 0 sein.",
                    timeout: 5000,
                });
                this.inserting_entry = false;
                return;
            }

            // check if dauer is set
            if (this.editedItem.dauer === null || this.editedItem.dauer === "" || this.editedItem.dauer === undefined) {
                this.$emit("showError", { 
                    message: "Bitte gib eine Dauer ein. Die Dauer kann auch 0 sein.",
                    timeout: 5000,
                });
                this.inserting_entry = false;
                return;
            }

            // check if uhrzeit is properly set
            if (this.editedItem.uhrzeit === null || this.editedItem.uhrzeit === "" || this.editedItem.uhrzeit === undefined || !this.editedItem.uhrzeit.match(/^\d{2}:\d{2}(?::\d{2})?$/)) {
                this.$emit("showError", { message: "Bitte gib eine Uhrzeit im Format HH:mm ein (z.B. 12:00)." });
                this.inserting_entry = false;
                return;
            }

            if (this.$refs.calendar.hasReachedAppointmentsLimit) {
                // check if the user has already a TEILZEIT subscription
                if (this.$store.getters.getTrueSubscriptionTier === "TEILZEIT") {
                    this.$emit("showError", {
                        message: "Du hast diesen Monat keine freien Termine mehr. Mit dem Vollzeit Abo kannst du unbegrenzt Termine anlegen.",
                        additional_button: {
                            text: "Abo Verwalten",
                            target: "manage-subscription",
                        },
                    });
                    this.inserting_entry = false;
                    return;
                } else {
                    this.$emit("showError", {
                        message: "Du hast diesen Monat keine freien Termine mehr. Mit dem Teilzeit oder Vollzeit Abo kannst du weitere Termine anlegen.",
                        additional_button: {
                            text: "Abos Anzeigen",
                            target: "/upgrade",
                        },
                    });
                    this.inserting_entry = false;
                    return;
                }
            }

            if (this.editedItem.id) {
                // check if the appointment is already verrechnet, if so do not allow to edit
                if (this.editedItem.fk_rechnungs_id) {
                    this.$emit("showError", {
                        message: "Der Termin kann nicht bearbeitet werden, da er bereits verrechnet ist. Lösche zuerst die entsprechende Rechnung.",
                    });
                    this.inserting_entry = false;
                    return;
                }

                /*
                    uid uuid not null,
                    inserted_at timestamp without time zone not null default now(),
                    updated_at timestamp without time zone not null default now(),
                    fk_klienten_id bigint not null,
                    fk_rechnungs_id bigint null,
                    datum timestamp without time zone not null,
                    preis double precision not null,
                    dauer double precision null,
                    bezeichnung text null,
                    ust_befreiung integer null,
                    fk_dienstleistung bigint null,
                    dokumentation bytea null,
                    erinnerung_gesendet boolean null,
                */

                let toInsert = {
                    fk_klienten_id: this.client.id,
                    datum: this.editedItem.datum.split("T")[0] + "T" + this.editedItem.uhrzeit,
                    preis: this.editedItem.preis,
                    dauer: this.editedItem.dauer,
                    bezeichnung: this.editedItem.bezeichnung,
                    ust_befreiung: this.editedItem.ust_befreiung,
                    fk_dienstleistung: this.service.id,
                    termin_erinnerung: this.editedItem.termin_erinnerung,
                };

                let updated_appointment = await connector.update(this, "termine", toInsert, this.editedItem.id);
                if (updated_appointment === null) {
                    // error was already shown, stop rest of the code
                    this.inserting_entry = false;
                    return;
                }

                // check if google calendar is connected, and if yes, update the event in the calendar
                if (this.$store.state.client.google_calendar) {
                    // update the date, as we need the full format in the updated event
                    this.editedItem.datum = this.editedItem.datum.split("T")[0] + "T" + this.editedItem.uhrzeit;
                    // also set the name, as we do not decrypt the appointment data
                    this.editedItem.vorname = this.client.vorname;
                    this.editedItem.nachname = this.client.nachname;
                    let updated = await this.$refs.calendar.updateGoogleEvent(this.editedItem);
                }
                this.inserting_entry = false;
                this.closeCreateAppointment();
                this.$emit("refreshAppointments");

                if (updated_appointment) {
                    this.$emit('showInfo', {
                        message: 'Der Termin wurde erfolgreich aktualisiert.',
                        timeout: 5000
                    });
                }

            } else {
                // create new entry
                /*
                    uid uuid not null,
                    inserted_at timestamp without time zone not null default now(),
                    updated_at timestamp without time zone not null default now(),
                    fk_klienten_id bigint not null,
                    fk_rechnungs_id bigint null,
                    datum timestamp without time zone not null,
                    preis double precision not null,
                    dauer double precision null,
                    bezeichnung text null,
                    ust_befreiung integer null,
                    fk_dienstleistung bigint null,
                    dokumentation bytea null,
                    termin_erinnerung boolean null,
                */

                let toInsert = [
                    {
                        uid: this.session.user.id,
                        fk_klienten_id: this.client.id,
                        //fk_rechnungs_id: null, // not set here, as this is a new entry
                        datum: this.editedItem.datum.split("T")[0] + "T" + this.editedItem.uhrzeit,
                        preis: this.editedItem.preis,
                        dauer: this.editedItem.dauer,
                        bezeichnung: this.editedItem.bezeichnung,
                        ust_befreiung: this.editedItem.ust_befreiung,
                        fk_dienstleistung: this.service.id,
                        termin_erinnerung: this.editedItem.termin_erinnerung, 
                        //document: null, // not set here, as this is a new entry
                    },
                ];

                let entries = await connector.inserts(this, "termine", toInsert);
                if (entries === null) {
                    // error has already been show, stop execution
                    this.inserting_entry = false;
                    return null;
                }

                let google_error = false;
                // check if google calendar is connected, and if yes, insert the event in the calendar
                if (this.$store.state.client.google_calendar) {
                    try {
                        let events = entries.map((event) => {
                            let client_name = "Klienten-Termin";

                            if (this.client.vorname && this.client.nachname) {
                                if (this.$store.state.client.google_calendar_anonymize) {
                                    client_name = this.client.nachname.slice(0, 2) + this.client.vorname.slice(0, 2);
                                } else {
                                    client_name = this.client.nachname + " " + this.client.vorname;
                                }
                            }

                            return {
                                calendarId: this.$store.state.client.google_calendar,
                                start: {
                                    dateTime: dayjs(event.datum).format("YYYY-MM-DDTHH:mm:ss"),
                                    timeZone: "Europe/Berlin",
                                },
                                end: {
                                    dateTime: dayjs(event.datum).add(event.dauer, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
                                    timeZone: "Europe/Berlin",
                                },
                                summary: client_name,
                                description: "https://app.zeipsy.com/termine/?id=" + event.id,
                            };
                        });

                        let inserted = await this.$refs.calendar.insertGoogleEvents(events);
                    } catch (err) {
                        console.log(err);
                        this.$emit("showError", {
                            message: "Der Termin wurde erfolgreich angelegt, konnte jedoch nicht in deinem Google Kalender eingetragen werden.",
                        });
                        google_error = true;
                        return [];
                    }
                }

                this.$store.commit('setOnboardingAppointmentStatus', true);
                this.inserting_entry = false;
                this.closeCreateAppointment();
                this.$emit("refreshAppointments");
                if (entries && !google_error) {
                    this.$emit('showInfo', {
                        message: (entries.length > 1 ? 'Die Termine wurden erfolgreich erstellt.' : 'Der Termin wurde erfolgreich erstellt.'),
                        timeout: 5000
                    });
                }
            }
        },
    },
};
</script>
