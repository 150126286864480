<template>
    <v-card :color="$store.state.theme.background">
        <v-toolbar dark :color="$store.state.theme.primary">
            <v-btn icon dark @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Monatsabrechnung</v-toolbar-title>
            <v-spacer></v-spacer>
            <strong v-if="haveCustomersToInvoice" class="white--text">Rechnungen bearbeitet</strong>
            <v-progress-circular
                v-if="haveCustomersToInvoice"
                :size="50"
                :width="5"
                :value="progress"
                :rotate="-90"
                :color="'white'"
                class="ml-4"
            >
                <span :style="{ fontSize: fontSize }">{{ current_client_idx }} / {{ customers.length }}</span>
            </v-progress-circular>
        </v-toolbar>

        <v-card v-if="!finished_creating_invoices && haveCustomersToInvoice" class="mx-5 mt-5">
            <v-stepper v-model="step" elevation="0" class="my-0 py-0">
                <v-stepper-header class="elevation-1">
                    <div class="d-flex align-center px-5">
                        <v-icon large :color="$store.state.theme.primary" class="mr-3">mdi-account</v-icon>
                        <span class="text-h6">{{ nameOfCurrentClient }}</span>
                        <v-chip v-for="tag in tagsOfCurrentClient" :key="'chip-' + tag.id" class="ml-2" :small="$vuetify.breakpoint.smAndDown">{{ tag.merkmal }}</v-chip>
                    </div>
                    <div class="mx-2" style="border-left: 1px solid #d3d3d3; height: 50%; margin: auto 0"></div>
                    <v-stepper-step :color="$store.state.theme.primary" :complete="step > 1" step="1">
                        Rechnung erstellen
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :color="$store.state.theme.primary" :complete="step > 2" step="2">
                        Versenden
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card flat class="mb-0">
                            <v-card-title class="px-0 pt-0">
                                Rechnungsdetails
                            </v-card-title>
                            <v-card-text class="px-0">
                                <v-row>
                                    <v-col cols="12" class="d-flex">
                                        <div style="max-width: 300px;">
                                            <v-menu v-model="menu" :close-on-content-click="false" offset-y max-width="290px" min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field dense outlined v-model="formattedDate" label="Rechnungsdatum"
                                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="mr-5"
                                                    />
                                                </template>
                                                <v-date-picker first-day-of-week="1" v-model="datum" no-title @input="menu = false" />
                                            </v-menu>
                                        </div>
                                        <div style="max-width: 300px;">
                                            <v-text-field dense outlined v-model="nummer" label="Rechnungsnummer" />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card flat class="mt-0 pt-0">
                            <v-card-title class="pb-2 px-0 pt-0">
                                Verrechenbare Termine
                                <v-chip :color="$store.state.theme.primary" dark small class="ml-2">
                                    <v-icon v-if="selected_appointments.length === 0" left small> mdi-cursor-default-click-outline </v-icon>
                                    {{ selected_appointments.length === 0 ? 'Wähle die zu verrechnenden Termine aus' : `${selected_appointments.length} ausgewählt` }}
                                </v-chip>
                            </v-card-title>
                            <v-data-table
                                v-model="selected_appointments"
                                :headers="headers_appointments"
                                hide-default-footer
                                :items="appointmentsOfCurrentClient"
                                item-key="id"
                                show-select
                                class="elevation-1 mt-1 mx-1 clickable-rows"
                                :items-per-page="-1"
                                @click:row="toggleSelection"
                            >
                                <template v-slot:item.datum="{ item }">
                                    <span>
                                        {{
                                            new Date(item.datum).toLocaleString([], {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })
                                        }}
                                    </span>
                                    <v-chip v-if="isAfterNow(item.datum)" :color="$store.state.theme.orange"
                                        dark small label class="ml-2">
                                        <v-tooltip bottom :color="$store.state.theme.primary">
                                            <template v-slot:activator="{ on }">
                                                <span class="font-weight-black" v-on="on">Z</span>
                                            </template>
                                            Dieser Termin liegt in der Zukunft.
                                        </v-tooltip>
                                    </v-chip>
                                </template>
                                <template v-slot:item.preis="{ item }">
                                    <span v-if="item.dauer">{{ item.preis }}€ ({{ item.dauer }} Minuten)</span>
                                    <span v-else>{{ item.preis }} €</span>
                                </template>
                                <template v-slot:item.name="{ item }">
                                    {{ item.nachname }} {{ item.vorname }}
                                </template>
                                <template v-slot:body.append="{ headers }">
                                    <tr class="no-hover">
                                        <td class="py-3" :colspan="headers.length - 1">
                                            <div class="subtitle-1 font-weight-medium black--text">Rechnungsbetrag</div>
                                            <div class="subtitle-2 font-weight-regular">= Summe der ausgewählten Termine</div>
                                        </td>
                                        <td class="py-3">
                                            <div class="text-h5 black--text font-weight-bold">
                                                {{ amountOfSelectedAppointments }} €
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card>

                        <v-row class="mt-4 pb-4">
                            <v-col class="d-flex justify-space-between">
                                <v-btn :color="$store.state.theme.green" @click="createInvoice" dark :loading="creating_invoice">
                                    <v-icon left>mdi-download</v-icon>
                                    Erstellen & Herunterladen
                                </v-btn>
                                <v-btn outlined @click="nextClient">
                                    Klient Überspringen
                                    <v-icon right>mdi-arrow-right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-row class="mt-0">
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <v-card outlined>
                                    <v-card-title class="justify-center">
                                        Bitte überprüfe die Rechnung vor dem Versenden der E-Mail
                                    </v-card-title>
                                    <v-card-text>
                                        <object
                                            v-if="current_invoice_url"
                                            :data="current_invoice_url"
                                            type="application/pdf"
                                            width="100%"
                                            :style="{ height: computedHeight + 'px' }"
                                        >
                                            <p>
                                                Rechnung kann nicht angezeigt werden. Lade die Rechnung
                                                <a :href="current_invoice_url">herunter</a> und überprüfe sie bevor du
                                                sie verschickst.
                                            </p>
                                        </object>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <v-card outlined>
                                    <v-card-title class="justify-center"> E-Mail Details </v-card-title>
                                    <v-container class="px-5">
                                        <v-form v-model="valid_email">
                                            <div class="d-flex align-items-center justify-center">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    v-model="current_client.email"
                                                    label="E-Mail Adresse"
                                                    :rules="emailRules"
                                                    ref="recipient_email_quick"
                                                >
                                                    <template v-slot:append v-if="current_invoice && !current_invoice.rechnungs_empfänger">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon v-bind="attrs" v-on="on" @click="updateCustomerMail"
                                                                    >mdi-content-save</v-icon
                                                                >
                                                            </template>
                                                            <span>E-Mail Adresse für den Klienten speichern.</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-text-field>
                                                <v-checkbox
                                                    :color="$store.state.theme.primary"
                                                    v-model="send_to_me"
                                                    label="Kopie an mich selbst senden (CC)"
                                                    class="ml-5 my-auto py-0"
                                                />
                                            </div>
                                        </v-form>
                                        
                                        <span class="text-h6 text--black mt-0 mb-3">E-Mail Nachricht</span>
                                        <v-text-field class="mt-5 mb-0 pb-0" dense outlined v-model="subject" label="Betreff" />
                                        <v-textarea
                                            v-model="draft_message"
                                            label="Nachricht"
                                            auto-grow
                                            outlined
                                            class="mb-0 pb-0"
                                        ></v-textarea>

                                        <v-alert class="mb-4 mt-0" dense text icon="mdi-paperclip">
                                            Die Rechnung wird automatisch als PDF der E-Mail angehängt.
                                        </v-alert>

                                        <p>
                                            <span class="font-weight-bold">Tipp:</span> Die Vorlage für den Betreff und die Nachricht kann in den Einstellungen angepasst werden.
                                        </p>

                                        <v-row no-gutters class="justify-center mt-2">
                                            <v-btn
                                                :disabled="!valid_email"
                                                :color="$store.state.theme.green"
                                                @click="sendInvoice"
                                                :dark="valid_email"
                                                :loading="sending_invoice"
                                            >
                                                <v-icon left>mdi-paperclip</v-icon>
                                                Versenden
                                            </v-btn>
                                            <v-btn outlined @click="nextClient" class="ml-5" :disabled="sending_invoice">
                                                Überspringen
                                                <v-icon right>mdi-arrow-right</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>

        <v-card v-else-if="!haveCustomersToInvoice" class="mx-5 mt-5 justify-center">
            <v-card-title class="justify-center">
                <v-icon left :color="$store.state.theme.green"> mdi-check-circle </v-icon>
                Aktuell gibt es keine Rechnungen zu erstellen.
            </v-card-title>
            <v-card-text class="d-flex justify-center">
                <v-btn :color="$store.state.theme.primary" @click="close" dark>
                    <v-icon left> mdi-check </v-icon>
                    Schließen
                </v-btn>
            </v-card-text>
        </v-card>

        <v-card v-else class="mx-5 mt-5 justify-center">
            <v-card-title class="justify-center">
                <v-icon left :color="$store.state.theme.green"> mdi-check-circle </v-icon>
                Monatsabrechnung abgeschlossen
            </v-card-title>
            <v-card-text class="d-flex justify-center">
                <v-btn :color="$store.state.theme.primary" @click="close" dark>
                    <v-icon left> mdi-check </v-icon>
                    Schließen
                </v-btn>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script>
import dayjs from 'dayjs';
import connector from '../helpers/supabase-connector.js';
import cipher from '@/helpers/cipher';
import invoiceHelper from '../helpers/invoices.js';
import { Buffer } from 'buffer';

export default {
    props: ['session', 'customers', 'appointments', 'invoice_number', 'invoices', 'dialog_quick_rechnung'],
    emits: ['close', 'showError', 'showInfo', 'updateInvoices', 'downloadInvoice'],

    data() {
        return {
            valid_email: false,
            emailRules: [
                v => !!v || 'E-Mail Adresse wird benötigt', // checks if the field is not empty
                v => /.+@.+\.[A-Za-z]{2,}/.test(v) || 'Gib eine gültige E-Mail Adresse an' // checks if the email is valid
            ],

            computedHeight: window.innerHeight, // for object in pdf viewer
            menu: false,
            step: 1,
            datum: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),

            creating_invoice: false,

            headers_appointments: [
                { text: 'Klient', value: 'name' },
                { text: 'Datum', value: 'datum' },
                { text: 'Dienstleistung', value: 'bezeichnung' },
                { text: 'Preis', value: 'preis' }
            ],

            default_client: {
                fk_klienten_id: null,
                name: null,
                email: null,
            },

            current_client: {
                fk_klienten_id: null,
                name: null,
                email: null,
            },

            selected_appointments: [],
            current_client_idx: 0,
            nummer: this.invoice_number,
            finished_creating_invoices: false,
            last_invoice_id: null,
            current_invoice: null,
            current_invoice_url: null,
            current_invoice_blob: null,
            send_to_me: true,
            sending_invoice: false,

            subject: 'Rechnung',
            draft_message: 'Hallo {empfänger_vorname},\n\nanbei die aktuelle Rechnung.\n\nViele Grüße,\n{praxis_name}',
        };
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        this.draft_message = 'Hallo {empfänger_vorname},\n\nanbei die aktuelle Rechnung.\n\nViele Grüße,\n{praxis_name}';
        this.subject = 'Rechnung';

        // check if email_betreff and email_nachricht are set, if so, use them as default values
        if (this.$store.state.client.email_betreff) this.subject = this.$store.state.client.email_betreff;
        if (this.$store.state.client.email_nachricht) this.draft_message = this.$store.state.client.email_nachricht;

    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    watch: {
        invoice_number() {
            this.nummer = this.invoice_number;
        },
        dialog_quick_rechnung() {
            if (this.dialog_quick_rechnung) {
                // dialog opened, reset all values

                this.step = 1;
                this.current_client_idx = 0;
                this.current_client = Object.assign({}, this.default_client);
                this.current_invoice_blob = null;
                this.selected_appointments = [];
                this.finished_creating_invoices = false;
                this.current_invoice = null;
                this.last_invoice_id = null;
                this.send_to_me = true;
                this.subject = 'Rechnung';
                this.draft_message = 'Hallo {empfänger_vorname},\n\nanbei die aktuelle Rechnung.\n\nViele Grüße,\n{praxis_name}';

                // check if email_betreff and email_nachricht are set, if so, use them as default values
                if (this.$store.state.client.email_betreff) this.subject = this.$store.state.client.email_betreff;
                if (this.$store.state.client.email_nachricht) this.draft_message = this.$store.state.client.email_nachricht;
            }
        },
    },

    computed: {

        digitsInIndex() {
            return this.current_client_idx.toString().length + this.customers.length.toString().length;
        },
        fontSize() {
            return this.digitsInIndex > 3 ? '0.7rem' : '0.9rem';
        },

        formattedDate() {
            return dayjs(this.datum).format('DD.MM.YYYY');
        },

        nameOfCurrentClient() {
            if (this.customers.length === 0 || this.current_client_idx >= this.customers.length) {
                return '';
            }
            return this.customers[this.current_client_idx].name;
        },

        tagsOfCurrentClient() {
            if (this.customers.length === 0 || this.current_client_idx >= this.customers.length) {
                return [];
            }
            return this.customers[this.current_client_idx].merkmale;
        },

        appointmentsOfCurrentClient() {
            if (this.customers.length === 0 || this.current_client_idx >= this.customers.length) {
                return [];
            }
            return this.appointments.filter(
                (appointment) => appointment.fk_klienten_id == this.customers[this.current_client_idx].fk_klienten_id
            );
        },

        amountOfSelectedAppointments() {
            let sum = 0;
            this.selected_appointments.forEach((appointment) => {
                sum += appointment.preis;
            });
            return sum;
        },

        progress() {
            if (this.customers.length === 0) return 0;
            return (this.current_client_idx / this.customers.length) * 100;
        },

        haveCustomersToInvoice() {
            return this.customers.length > 0;
        },
    },

    methods: {

        toggleSelection(item) {
            const index = this.selected_appointments.findIndex(selected => selected.id === item.id);
            if (index === -1) {
                this.selected_appointments.push(item);
            } else {
                this.selected_appointments.splice(index, 1);
            }
        },

        handleResize() {
            this.computedHeight = window.innerHeight - 300;  
        },

        isAfterNow(dateString) {
            const parsed_date = dayjs.tz(dateString, 'YYYY-MM-DDTHH:mm:ss', 'Europe/Vienna');
            const now = dayjs().tz('Europe/Vienna');
            return parsed_date.isAfter(now);
        },

        async createInvoice() {
            // check if invoice number is entered.
            if (this.nummer === '' || this.nummer === null || !this.nummer) {
                this.$emit('showError', { message: 'Eine Rechungsnummer muss vergeben werden.' });
                return;
            }

            let invoice = {
                uid: this.session.user.id,
                nummer: this.nummer,
                datum: this.datum,
                fk_klienten_id: this.customers[this.current_client_idx].fk_klienten_id,
                fk_institution_id: null
            };

            // if (!this.$store.state.client.eigenes_schema) {
            //     let invoice_regex = /^\d+[a-zA-Z]?([/])\d{4}$/;
            //     if (!invoice_regex.test(invoice.nummer)) {
            //         this.$emit('showError', {
            //             message: 'Die Rechungsnummer muss folgendes Format haben: NR/JJJJ (zb. 1/2022).',
            //         });
            //         return;
            //     }
            // }

            // check if invoice number is in correct format
            if (this.$store.state.client.rechnungsnummer_format) {
                const invoice_regex = invoiceHelper.generateInvoiceRegex(this.$store.state.client.rechnungsnummer_format);
                if (!invoice_regex.test(invoice.nummer)) {
                    this.$emit('showError', { 'message': "Die Rechungsnummer muss folgendes Format haben: " + this.$store.state.client.rechnungsnummer_format });
                    return;
                }
            } else if (this.$store.state.client.eigenes_schema) {
                // do nothing, as we do not have a client schema
            } else {
                const invoice_regex = invoiceHelper.generateInvoiceRegex('{NR}/{JAHR}');
                if (!invoice_regex.test(invoice.nummer)) {
                    this.$emit('showError', { 'message': "Die Rechungsnummer muss folgendes Format haben: NR/JJJJ (zb. 1/2022)." });
                    return;
                }
            }

            if (this.selected_appointments.length === 0) {
                this.$emit('showError', { message: 'Mindestens ein Termin muss für eine Rechnung ausgewählt werden.' });
                return;
            }

            this.creating_invoice = true;

            this.last_invoice_id = await connector.createInvoice(this, invoice, this.selected_appointments.map((appointment) => appointment.id));
            if (!this.last_invoice_id) {
                // error was alredy shown in createInvoice
                this.creating_invoice = false;
                // do not increase step
                return;
            }

            this.draft_message = 'Hallo {empfänger_vorname},\n\nanbei die aktuelle Rechnung.\n\nViele Grüße,\n{praxis_name}';
            this.subject = 'Rechnung';
            
            // check if email_betreff and email_nachricht are set, if so, use them as default values
            if (this.$store.state.client.email_betreff) this.subject = this.$store.state.client.email_betreff;
            if (this.$store.state.client.email_nachricht) this.draft_message = this.$store.state.client.email_nachricht;

            this.$emit('updateInvoices', (invoices) => {
                // set invoice item for download
                this.current_invoice = invoices.find((invoice) => invoice.id === this.last_invoice_id);
                this.current_client = Object.assign({}, this.customers[this.current_client_idx]);

                // we need to set it here, as we cannot do it in the DB as we would mix unencrypted and encrypted data types
                if (this.current_invoice.rechnungs_empfänger === 'institution' && (this.current_client.email === null || this.current_client.email === "") && this.current_invoice.empfänger_email !== null) {
                    this.current_client.email = this.current_invoice.empfänger_email;
                }

                this.$nextTick(() => {
                    if ('recipient_email_quick' in this.$refs) this.$refs['recipient_email_quick'].validate(true);
                }); 

                // download the invoice
                this.downloadInvoice();
            });
        },

        downloadInvoice() {
            
            this.$emit('downloadInvoice', this.current_invoice, (invoice_blob) => {

                if (invoice_blob === null || invoice_blob.status !== "success") {
                    // in this case, there are some missing values in the invoice, dialog was already shown
                    this.creating_invoice = false;
                    this.nextClient();
                    return;
                }

                this.current_invoice_blob = invoice_blob.blob;
                this.current_invoice_url = URL.createObjectURL(invoice_blob.blob);
                this.creating_invoice = false;
                this.step++;

                // replace placeholders in the message
                this.draft_message = this.draft_message
                    .replace('{vorname}', this.current_client.vorname)
                    .replace('{nachname}', this.current_client.nachname)
                    .replace('{titel_vorgestellt}', this.current_client.titel_vorgestellt)
                    .replace('{titel_nachgestellt}', this.current_client.titel_nachgestellt)
                    .replace('{empfänger_vorname}', this.current_client.empfänger_vorname)
                    .replace('{empfänger_nachname}', this.current_client.empfänger_nachname)
                    .replace('{empfänger_titel_vorgestellt}', this.current_client.empfänger_titel_vorgestellt)
                    .replace('{empfänger_titel_nachgestellt}', this.current_client.empfänger_titel_nachgestellt)
                    .replace('{praxis_name}', this.$store.state.client.name)
                    .replace(/[ \t]+(\r\n|\n|\r)/g, '$1'); // Use a regular expression to remove additional whitespaces before a linebreak
            });
        },

        async updateCustomerMail() {
            let toInsert = {
                email: this.current_client.email.trim(),
            };
            let encrypted_obj = await cipher.encryptObject(this.$store.state.aes_key, toInsert);
            await connector.updateRow(this, 'klienten', encrypted_obj, this.current_client.fk_klienten_id);
            this.$emit('showInfo', 'E-Mail Adresse wurde aktualisiert.');
        },

        async sendMail(email) {
            var myHeaders = new Headers();
            myHeaders.append('Authorization', 'Bearer ' + this.session.access_token);
            myHeaders.append('Accept', '*/*');
            myHeaders.append('x-client-info', 'supabase-js/2.8.0');

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(email),
            };

            let responseJson = {};
            try {
                let response = await fetch(
                    'https://qgsfsflpvymafizvkpca.functions.supabase.co/send-email/',
                    requestOptions
                );
                responseJson = await response.json();
            } catch (error) {
                console.log('error during POST:', error);
            }

            return responseJson;
        },

        async sendInvoice() {
            if (this.current_client.email === null || this.current_client.email === '') {
                this.$emit('showError', { message: 'Keine E-Mail Adresse angegeben.' });
                return;
            }

            if (this.subject === '' || this.subject === null) {
                this.$emit('showError', { message: 'Kein Betreff angegeben.' });
                return;
            }

            if (this.draft_message === '' || this.draft_message === null) {
                this.$emit('showError', { message: 'Keine Nachricht angegeben.' });
                return;
            }

            // send invoice
            this.sending_invoice = true;

            // convert blob to uint8Array
            let invoice_binary = Buffer.from(await this.current_invoice_blob.arrayBuffer());

            let email = {
                name: this.$store.state.client.name,
                recipient: this.current_client.email.trim(),
                subject: this.subject,
                content: this.draft_message.trim(),
                reply_to: this.session.user.email,
                invoice_blob: invoice_binary,
                cc: this.send_to_me ? this.session.user.email : null,
            };

            let response = await invoiceHelper.sendInvoiceMail(this, email);

            if (response !== null && 'status' in response && response.status) {
                // mark invoice as sent
                let toInsert = {
                    versendet: true,
                };

                let updated = await connector.update(this, 'rechnungen', toInsert, this.current_invoice.id);
                if (updated === null) {
                    // error has already been shown, but overwrite it with more proper message
                    this.$emit('showError', {
                        message: 'Die E-Mail wurde erfolgreich versendet, jedoch konnte der Status des E-Mail Versands nicht gespeichert werden.',
                        timeout: 10000,
                    });
                } else {
                    this.$emit('showInfo', {
                        message: 'Die Rechnung wurde erfolgreich versendet.',
                        timeout: 5000
                    });
                }
                this.sending_invoice = false;
            } else {
                this.$emit('showError', { message: 'Die Rechnung konnte nicht versendet werden.' });
                this.sending_invoice = false;
                return;
            }
            this.nextClient();
        },

        nextClient() {
            // to clear cache
            if (this.current_invoice_url !== null) {
                URL.revokeObjectURL(this.current_invoice_url);
                this.current_invoice_url = null;
            }

            if (this.current_client_idx < this.customers.length - 1) {
                this.current_client_idx++;
                this.selected_appointments = [];
                this.step = 1;
                this.current_invoice = null;
                this.current_invoice_blob = null;
                this.last_invoice_id = null;
                return;
            }
            // also +1 because of 0-index to show that all invoices are created
            this.current_client_idx++;
            this.finished_creating_invoices = true;
        },

        close() {
            // to clear cache
            if (this.current_invoice_url !== null) {
                URL.revokeObjectURL(this.current_invoice_url);
                this.current_invoice_url = null;
            }

            this.step = 1;
            this.current_client_idx = 0;
            this.current_client = Object.assign({}, this.default_client);
            this.current_invoice_blob = null;
            this.selected_appointments = [];
            this.finished_creating_invoices = false;
            this.current_invoice = null;
            this.last_invoice_id = null;
            this.send_to_me = true;
            this.subject = 'Rechnung';

            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
.v-data-table >>> .no-hover:hover {
  background-color: transparent !important;
  cursor: default;
}
.clickable-rows ::v-deep tbody tr {
  cursor: pointer;
}
</style>