<template>
    <div>
        <v-dialog v-model="dialog_rename_file" max-width="700px" persistent>
            <v-card>
                <v-card-title>Neuer Name</v-card-title>
                <v-card-text class="mb-0 pb-0">
                    <v-text-field v-model="file_name" dense outlined label="Dateiname" />
                </v-card-text>
                <v-card-actions class="mt-0 pt-0 px-6 pb-5">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.red" @click="dialog_rename_file = false" :disabled="renaming_file">
                        Abbrechen
                    </v-btn>
                    <v-btn outlined :color="$store.state.theme.green" @click="renameFile" :loading="renaming_file">
                        Umbenennen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_file_preview" persistent fullscreen>
            <v-card flat tile>
                <!-- Toolbar -->
                <v-toolbar flat :color="$store.state.theme.primary">
                    <v-btn icon @click="closeFile">
                        <v-icon class="white--text">mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title class="white--text">
                        <span v-if="current_file" class="break-words">{{ current_file.name }}</span>
                        <span v-else>Datei-Vorschau</span>
                    </v-toolbar-title>
                </v-toolbar>

                <!-- Content Area -->
                <v-row no-gutters>
                    <!-- Image Preview -->
                    <v-col cols="12" md="8" class="d-flex justify-center pa-4">
                        <iframe v-if="file_preview_url && ['application/pdf'].includes(current_file.mimetype)"
                            :src="file_preview_url"
                            :style="{ width: '100%', height: computedHeight + 'px', border: 'none' }">
                        </iframe>
                        <v-img v-else-if="file_preview_url && ['image/png', 'image/jpeg', 'image/heic'].includes(current_file.mimetype)"
                            contain :src="file_preview_url" :lazy-src="file_preview_url" :height="computedHeight + 'px'" />
                        <span v-else class="text-h5 mt-5 pt-5">Keine Vorschau möglich.</span>
                    </v-col>

                    <!-- File Details -->
                    <v-col cols="12" md="4" class="pa-4">
                        <div v-if="current_file" class="body-1 mb-3">
                            Zuletzt bearbeitet am {{ formatDate(current_file.updated_at) }}
                        </div>
                        <div v-if="current_file" class="body-1 mb-3">Größe: {{ current_file.size }}</div>

                        <v-btn v-if="$vuetify.breakpoint.mdAndUp && showFormEditButton" class="mr-5 mb-3" @click="editForm">
                            <v-icon left>mdi-text-box-edit-outline</v-icon>
                            Antrag Bearbeiten
                        </v-btn>
                        <v-tooltip v-else-if="$vuetify.breakpoint.smAndDown && showFormEditButton" slot="activator" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <v-btn v-bind="attrs" v-on="on"  class="mr-5 mb-3" disabled>
                                        <v-icon left>mdi-text-box-edit-outline</v-icon>
                                        Antrag Bearbeiten
                                    </v-btn>
                                </div>
                            </template>
                            <span>Der Antrag kann nur auf einem Gerät mit größerem Bildschirm bearbeitet werden.</span>
                        </v-tooltip>
                        <v-btn v-if="!showFormEditButton" class="mr-5 mb-3" dark :color="$store.state.theme.green" @click="downloadFile(current_file)" :loading="downloading_file">
                            <v-icon left>mdi-download</v-icon>
                            Herunterladen
                        </v-btn>
                        <v-btn class="mr-5 mb-3" dark :color="$store.state.theme.primary" @click="showRenameDialog(current_file)">
                            <v-icon left>mdi-pencil</v-icon>
                            Umbenennen
                        </v-btn>
                        <v-btn class="mb-3" dark :color="$store.state.theme.red" @click="deleteFile(current_file)" :loading="deleting_file">
                            <v-icon left>mdi-delete</v-icon>
                            Löschen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <AntragKlinPsy ref="antrag_klinpsy" @showError="$emit('showError', $event)" @showInfo="$emit('showInfo', $event)" :session="session" :current_file="current_file" />
    </div>
</template>

<script>
import { saveAs } from 'file-saver';
import connector from '../helpers/supabase-connector.js';
import cipher from '../helpers/cipher.js';
import dayjs from 'dayjs';
import AntragKlinPsy from '@/components/AntragKlinPsy.vue';

export default {

    props: ['session', 'client_id'],
    emits: ['showError', 'showInfo', 'getBucketFiles'],

    components: {
        AntragKlinPsy
    },

    data() {
        return {
            dialog_file_preview: false,
            dialog_rename_file: false,
            file_name: null,
            file_preview_url: null,
            computedHeight: window.innerHeight,
            current_file: null,

            deleting_file: false,
            renaming_file: false,
            downloading_file: false,
        };
    },

    computed: {
        showFormEditButton() {
            return this.current_file && this.current_file.name.endsWith('.kpzpsy');
        }
    },

    methods: {

        formatDate(date) {
            return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
        },

        async editForm() {
            // check if the file name ends with '.kpzpsy', if so, navigate to the form and prefill the form
            if (this.current_file.name.endsWith('.kpzpsy')) {
                try {
                    let blob_file = await this.downloadFile(this.current_file, false);
                    if (blob_file === null) {
                        // error has already been shown
                        return;
                    }
                    let array_buffer = await blob_file.arrayBuffer();
                    const file_data = JSON.parse(new TextDecoder().decode(array_buffer));

                    this.dialog_file_preview = true;

                    this.$refs['antrag_klinpsy'].editForm(file_data);
                } catch (error) {
                    this.$emit('showError', {
                        message: 'Der Antrag konnte nicht geöffnet werden. Bitte versuche es erneut.',
                        error: error,
                        timeout: 5000
                    });
                }
                return;
            }
        },

        async openFile(file) {
            // download the file and then display it in a dialog in an object tag
            const download_file = await this.downloadFile(file, false);
            if (download_file === null) {
                // error has already been shown
                return;
            }
            const url = URL.createObjectURL(download_file);
            this.current_file = file;
            this.file_preview_url = url;
            this.dialog_file_preview = true;
        },

        getFileIcon(type) {
            // Return an icon based on the file type
            switch (type) {
                case 'application/pdf':
                    return 'mdi-file-pdf-box';
                case 'image/jpeg':
                case 'image/png':
                case 'image/heic':
                    return 'mdi-file-image';
                // Add more cases for different file types
                default:
                    return 'mdi-file-document-outline';
            }
        },

        getIconColor(type) {
            // Return a color based on the file type
            switch (type) {
                case 'application/pdf':
                    return 'red';
                case 'image/jpeg':
                case 'image/png':
                case 'image/heic':
                    return 'orange';
                // Add more cases for different file types
                default:
                    return 'blue';
            }

        },

        showRenameDialog(file) {
            this.file_name = file.name;
            this.file_to_rename = file;
            this.dialog_rename_file = true;
        },

        async renameFile() {
            if (this.file_to_rename) {

                // check if filename does not contain invalid characters, especial slashes!
                // check if the file name contains special characters, if so, show an error message and skip the file
                if (this.file_name.match(/[^a-zA-Z0-9. \-()_]/)) {
                    this.$emit('showError', {
                        message: 'Dateinamen dürfen keine Sonderzeichen, Umlaute oder ß enthalten.',
                    });
                    return;
                }

                this.renaming_file = true;
                let bucket = 'documentation';
                let path = this.session.user.id + '/' + this.client_id + '/';

                if (this.file_to_rename.appointment_id) {
                    path += this.file_to_rename.appointment_id + '/';
                }

                try {
                    let renamed_file = await connector.moveFileInBucket(this, bucket, path, this.file_to_rename.name, this.file_name);
                    if ('message' in renamed_file) {
                        this.$emit('showInfo', {
                            message: 'Die Datei wurde erfolgreich umbenannt.',
                            timeout: 5000
                        });
                        // update the preview
                        this.current_file.name = this.file_name;
                    }

                    this.file_name = null;
                    this.file_to_rename = null;
                    this.$emit('getBucketFiles');
                    this.dialog_rename_file = false;

                } catch (error) {
                    if ('message' in error && error.message.includes('The resource already exists')) {
                        this.$emit('showError', {
                            message: 'Es existiert bereits eine Datei mit dem selben Namen. Bitte wähle einen anderen Namen.',
                            timeout: 10000
                        })
                    }
                } finally {
                    this.renaming_file = false;
                }
                
            } else {
                this.$emit('showError', {
                    message: 'Die Datei konnte nicht umbenannt werden.',
                    timeout: 5000
                });
            }
        },

        async downloadFile(file, save = true) {
            try {

                if (save) this.downloading_file = true;
                // download the file from the bucket
                let bucket = 'documentation';
                let path = this.session.user.id + '/' + this.client_id + '/';

                if (file.appointment_id) {
                    path += file.appointment_id + '/';
                }

                // check if aes_key_file is set, if not then try to load it again
                if (!this.$store.state.aes_key_file) {
                    let keys = await cipher.getAESKeys(this);
                    this.$store.state.aes_key_file = keys['aes_key_file'];
                }

                const bucket_file = await connector.downloadFile(this, bucket, path, file.name);
                if (bucket_file === null) {
                    // error has already been shown
                    if (save) this.downloading_file = false;
                    return null;
                }

                // convert the array buffer to a dictionary
                const fileData = new TextDecoder().decode(bucket_file);
                const fileDataJSON = JSON.parse(fileData);

                // decrypt the file
                let decrypted_file = await cipher.decryptFile(this.$store.state.aes_key_file, fileDataJSON);

                // save the file to the user's computer
                const blob = new Blob([decrypted_file], {
                    type: file.mimetype,
                });

                if (save) {
                    await saveAs(blob, file.name);
                    this.downloading_file = false;
                    this.$emit('showInfo', {
                        message: 'Die Datei wurde erfolgreich heruntergeladen.',
                        timeout: 5000
                    });
                } else {
                    return blob;
                }
            } catch (error) {

                let path = this.session.user.id + '/' + this.client_id + '/';

                if (file.appointment_id) {
                    path += file.appointment_id + '/';
                }

                connector.logError(this, {
                    uid: this.session.user.id,
                    message: 'ERROR opening documentation file: ' + error + ' path: ' + path + '/' + file.name,
                });

                if (save) this.downloading_file = false;
                return null;
            }
        },

        async deleteFile(file) {
            // delete the file from the bucket
            this.deleting_file = true;

            let bucket = 'documentation';
            let path = this.session.user.id + '/' + this.client_id + '/';
            if (file.appointment_id) {
                path += file.appointment_id + '/';
            }
            let deleted = await connector.deleteFileFromBucket(this, bucket, path, file.name);
            if (!deleted) {
                // error has already been shown
                this.deleting_file = false;
                return;
            }
            this.$emit('getBucketFiles');
            this.closeFile();
            this.deleting_file = false;
            this.$emit('showInfo', {
                message: 'Die Datei wurde erfolgreich gelöscht.',
                timeout: 5000
            });
        },

        closeFile() {
            this.dialog_file_preview = false;
            if (this.file_preview_url) URL.revokeObjectURL(this.file_preview_url);
            this.file_preview_url = null;
            this.current_file = null;
        },

    },
    watch: {

    },
};
</script>